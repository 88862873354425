import React, { useEffect } from 'react';

import { useAppSelector } from '@/app/hooks';
import { selectUserDetails } from '@/features/user/userSlice';
import {
  promotedInstituteModalShown,
  shouldShowPopup,
} from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';

const NewsletterAnnouncementWrapper = (props: {
  children: React.ReactNode;
}) => {
  const { showModal, hideModal } = useModal();
  const isPromoteInstituteBannerVisible = useAppSelector((state) =>
    selectUserDetails(state),
  )?.isPromotedInstituteCategoryEnabled;

  useEffect(() => {
    const showPromotedInstituteModal = shouldShowPopup();

    if (showPromotedInstituteModal && isPromoteInstituteBannerVisible) {
      showModal({
        modalType: MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT,
        modalProps: {
          onClose: () => {
            promotedInstituteModalShown();
            hideModal();
          },
        },
      });
    }
  }, [isPromoteInstituteBannerVisible]);

  return <>{props.children}</>;
};

export default NewsletterAnnouncementWrapper;
