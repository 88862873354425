import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { BaseResponse } from '@/types/Services';
import SnackbarUtils from '@/utils/snackbar';

import { PRE_LOGIN_ACCESS_PAGES } from './constants';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      action.payload?.status === 401 &&
      window.location.pathname !== action?.payload?.data?.path &&
      !PRE_LOGIN_ACCESS_PAGES.includes(window.location.pathname)
    ) {
      document.cookie =
        `token` + `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

      const serverPath = action?.payload?.data?.path
        ? `${action?.payload?.data?.path}?callbackUrl=${window.location.href}`
        : null;
      window.location.href =
        serverPath ?? `/login?callbackUrl=${window.location.href}`;
    }
    if (action.payload && action.payload.apiError) {
      if (action.payload.message && action.payload.message.trim().length > 0) {
        SnackbarUtils.error(action.payload.message);
      } else {
        SnackbarUtils.error(`Something went wrong.Please contact tech team`);
      }
    }
    return next(action);
  };

export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};
