import React, { useState } from 'react';

import { Animate } from '@leapfinance/frontend-commons';
import CustomSlider from '@leapfinance/frontend-commons/components/geebee/CustomSlider';
import {
  Button,
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { KeyboardArrowRight } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'next/image';
import Link from 'next/link';
import { useSnackbar } from 'notistack';

import {
  ApplicationEligibilityClickEventWrapper,
  ApplicationEligibilityEventType,
} from '@/components/ApplicationEligibilitySection/ApplicationEligibilityEventUtil';
import { CFCourse } from '@/components/CF/Dashbaord/helpers';
import EligibleCourseCard from '@/components/Modals/Application/EligibleCourseCard';
import useModal from '@/components/Modals/useModal';
import { addToShortlist } from '@/services/cf';
import { downloadCSVData } from '@/services/download';
import { definitions } from '@/types/schema';

import { MODAL_TYPES } from '../GlobalModal';

import { generateEligibleCoursesMessage } from './helpers';

export interface IEligibleCoursesModalProps {
  studentName: string;
  courses: CFCourse[];
  stage: string;
  mobileNumber: string;
  applicationId: number;
  country?: string;
  studentId?: number;
  intake?: string;
  degreeId: number;
  cfStudentName?: string;
  substreamName?: string;
  onModalClose?: () => void;
  university?: string;
  course?: string;
  nonEligibleReasons?: definitions['CourseNonEligibilityReasonDto'][];
  leadStage?: string;
}

const EligibleCoursesModal: React.FunctionComponent<
  IEligibleCoursesModalProps
> = (props) => {
  const [shortlistedCourseIds, setShortlistedCourseIds] = useState<number[]>(
    [],
  );
  const [activeShortlistingCourse, setActiveShortlistingCourse] = useState<
    number | undefined
  >();
  const [isContentCopied, setIsContentCopied] = useState<boolean>(false);
  const { hideModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleAddToShortlist = async (course: CFCourse) => {
    if (!course?.courseId) return;
    if (course?.courseId && shortlistedCourseIds?.includes(course?.courseId))
      return;
    setActiveShortlistingCourse(course?.courseId);
    try {
      await addToShortlist(props?.studentId, course).then((res) => {
        if (res.success) {
          setShortlistedCourseIds([
            ...shortlistedCourseIds,
            course?.courseId as number,
          ]);
          enqueueSnackbar(`Shortlist created sucessfully`, {
            variant: `success`,
          });
        } else {
          enqueueSnackbar(`Failed to add shortlist, please try again later`, {
            variant: `success`,
          });
        }
      });
    } catch (error) {
      console.error(
        `Error in raising shortlist from Eligible Courses Modal`,
        error,
      );
    } finally {
      setActiveShortlistingCourse(undefined);
    }
  };

  const getCourseCard = (course: CFCourse) => {
    return (
      <EligibleCourseCard
        courseDetails={course}
        isCourseShortlisted={shortlistedCourseIds?.includes(
          course?.courseId as number,
        )}
        isShortlistRequested={course?.courseId === activeShortlistingCourse}
        onShortlistSuccess={() => {
          handleAddToShortlist(course);
        }}
        onUniversityNameClick={() =>
          window?.open(
            `/course-details/${course?.courseId}?studentId=${props?.studentId}&studentName=${props?.studentName}`,
            `_blank`,
          )
        }
      />
    );
  };

  const getGroupedCards = (cards: React.ReactNode[]) => {
    const groupedCards = [];
    for (let index = 0; index < cards.length; index += 2) {
      const card1 = cards[index];
      const card2 = cards[index + 1];
      const groupLayout = (
        <div className="h-full grid grid-rows-2 gap-2">
          <div>{card1}</div>
          <div>{card2}</div>
        </div>
      );
      groupedCards.push(groupLayout);
    }
    groupedCards.push(
      <div className="flex flex-col justify-center items-center h-full">
        <div className="relative h-[70px] w-[70px]">
          <Image
            layout="fill"
            alt="open course finder"
            src={`/svgs/open-course-finder-placeholder.svg`}
          />
        </div>
        <GBTypography variant="sh1Semibold" className="mt-4 mb-3">
          Want more course options?
        </GBTypography>
        <GBTypography variant="body4" className="mb-6">
          Find eligible courses for {props?.studentName} on CourseFinder.
        </GBTypography>
        <Link
          href={`/course-finder?studentId=${props?.studentId}&name=${
            props?.studentName
          }
                      &studentEligbilityFilter=${JSON.stringify({
                        country: props?.country,
                        intake: props?.intake,
                        studentName: props?.cfStudentName,
                        courseName: props?.substreamName,
                        degree: props?.degreeId,
                      })}`}
          passHref
          className="min-w-fit"
        >
          <a target="_blank" className="min-w-fit">
            <Button
              onClick={() =>
                ApplicationEligibilityClickEventWrapper(
                  ApplicationEligibilityEventType.REDIDRECT_TO_CF,
                  {
                    leadStage: props?.leadStage ?? `NA`,
                    studentId: props?.studentId,
                    contentName: `Check on CF`,
                    source: `Course Suggestion Modal`,
                  },
                )
              }
            >
              Open Course Finder
            </Button>
          </a>
        </Link>
      </div>,
    );
    return groupedCards;
  };

  const sliderCards = (courses: CFCourse[]) => {
    const cards = [];
    for (let index = 0; index < courses.length; index += 2) {
      const course1 = courses[index];
      const course2 = courses[index + 1];
      cards.push(getCourseCard(course1));
      if (course2) cards.push(getCourseCard(course2));
    }

    return cards;
  };

  const handleWhatsAppRedirect = () => {
    const message = generateEligibleCoursesMessage({
      studentName: props?.studentName,
      college: props?.university ?? ``,
      course: props?.course ?? ``,
      eligibleCourses: props?.courses,
      type: `whatsapp`,
      nonEligibleReasons: props?.nonEligibleReasons,
    });

    window.open(
      `https://wa.me/${props?.mobileNumber}?text=${message}`,
      `_blank`,
    );
  };

  const handleDownloadCSV = () => {
    try {
      downloadCSVData(
        {
          courseIds: props?.courses?.map((c) => c?.courseId) ?? [],
          studentId: props?.studentId,
        },
        `/api/download?path=/v1/shortlist/export/courses`,
      );
    } catch (err) {
      enqueueSnackbar(`Something went wrong, please try again later`, {
        variant: `error`,
      });
    }
    // generateCourseExcel(
    //   props.courses,
    //   `${props?.studentName}'s Recommended Courses`,
    // );
  };

  const handleCopy = () => {
    const message = generateEligibleCoursesMessage({
      studentName: props?.studentName,
      college: props?.university ?? ``,
      course: props?.course ?? ``,
      eligibleCourses: props?.courses,
      type: `clipboard`,
      nonEligibleReasons: props?.nonEligibleReasons,
    });
    setIsContentCopied(true);
    navigator.clipboard.writeText(message);
    enqueueSnackbar(`Course Details copied`, { variant: `info` });
    setTimeout(() => {
      setIsContentCopied(false);
    }, 1000);
  };

  return (
    <GBModalWrapper
      headerProps={{
        title: `Recommended Eligible Courses`,
        onClose: () => {
          hideModal(MODAL_TYPES.ELIGIBLE_COURSES_MODAL);
          props?.onModalClose?.();
        },
      }}
    >
      {` `}
      <div className="p-4">
        <div className="grid grid-cols-1 grid-rows-[auto] gap-[16px] z-30">
          <>
            <div className="flex justify-center">
              <Animate
                animationProps={{
                  renderer: `svg`,
                  path: `/lottie/sucess.json`,
                  loop: true,
                }}
                containerClasses={`h-[5.7rem] w-[5.7rem]`}
              />
            </div>
            <div className="flex items-center justify-center">
              <GBTypography className="text-center" variant="sh1Semibold">
                Application created and added to {props.stage}
              </GBTypography>
              <Link
                href={`/profile/${props?.studentId}?key=APPLICATIONS&applicationId=${props?.applicationId}`}
                target="_blank"
                passHref={true}
              >
                <a target="_blank">
                  <Button
                    variant="tertiary"
                    size="medium"
                    className="flex justify-center items-center text-primary-500"
                  >
                    View Application
                    <KeyboardArrowRight />
                  </Button>
                </a>
              </Link>
            </div>
            <div
              style={{
                height: `1px`,
                borderImageSource: `radial-gradient(50% 7237715.1% at 50% 0%, #000000 0%, rgba(201, 199, 199, 0) 100%)`,
                background: `radial-gradient(50% 7237715.1% at 50% 0%, #000000 0%, rgba(201, 199, 199, 0) 100%)`,
              }}
            ></div>
            <div className="bg-warning-100 rounded-md flex items-start px-2.5 py-3 gap-x-2">
              <div>
                <WarningAmberRoundedIcon
                  className="text-error-700"
                  fontSize="medium"
                />
              </div>
              <div className="flex flex-col gap-3">
                <GBTypography
                  variant="sh2Semibold"
                  className="text-grey-900 font-bold"
                >
                  {props.studentName} is not eligible for this course!
                </GBTypography>
                <GBTypography
                  variant="body4"
                  className="font-normal mt-1 text-grey-900"
                >
                  Apply to eligible courses below for higher offer chances.
                </GBTypography>
              </div>
            </div>
          </>
        </div>
        <div className="p-1 flex items-center justify-between mt-4">
          <GBTypography
            variant="sh2Semibold"
            className="text-grey-900 font-bold"
          >
            Eligible courses
          </GBTypography>
          <div className="flex items-center justify-between gap-2">
            <IconButton onClick={handleDownloadCSV}>
              <FileDownloadOutlinedIcon
                fontSize="small"
                className="font-bold"
                sx={{ fontWeight: `700` }}
              />
            </IconButton>
            <IconButton onClick={() => handleCopy()}>
              {isContentCopied ? (
                <Tooltip title="Copied" placement="bottom" arrow={true}>
                  <ContentCopyOutlinedIcon fontSize="small" />
                </Tooltip>
              ) : (
                <ContentCopyOutlinedIcon fontSize="small" />
              )}
            </IconButton>
            <IconButton onClick={handleWhatsAppRedirect}>
              <WhatsAppIcon fontSize="small" className="text-success-500" />
            </IconButton>
          </div>
        </div>
        <Box
          sx={{
            marginY: `10px`,
            '& .slick-track': {
              display: `flex !important`,
              '.slick-slide': {
                height: `inherit !important`,

                '& > div': { height: `100% !important` },
              },
            },
          }}
        >
          <CustomSlider
            sliderProps={{ slidesToShow: 1, slidesToScroll: 1 }}
            slideContainerClasses={{
              'div:first-child': {
                height: `auto`,
              },
            }}
          >
            <>{getGroupedCards(sliderCards(props?.courses))}</>
          </CustomSlider>
        </Box>
      </div>
    </GBModalWrapper>
  );
};

export default EligibleCoursesModal;
