import { PageNames } from '@/types/segmentEvents';

const getPageName = () => {
  const pathName = window.location.pathname;
  const queryParams = window.location.search;
  if (pathName === `/applications`) return PageNames.APPLICATION_DASHBOARD;
  else if (pathName === `/students`) return PageNames.STUDENT_DASHBOARD;
  else if (pathName?.split(`/`)?.[1] === `/knowledge-library`)
    return PageNames.STUDENT_DASHBOARD;
  else if (pathName === `/notifications/all`) return PageNames.NOTIFICATION;
  else if (pathName?.split(`/`)?.[1] === `course-finder`)
    return PageNames.COURSE_FINDER;
  else if (pathName === `/summary-dashboard`) {
    return PageNames.SUMMARY_DASHBOARD;
  } else if (
    queryParams?.includes(`applicationId`) &&
    queryParams?.includes(`APPLICATIONS`)
  ) {
    return PageNames.APPLICATION_PAGE;
  } else if (pathName?.split(`/`)?.[1] === `university`) {
    return PageNames.UNIVERSITY_PAGE;
  } else if (pathName?.split(`/`)?.[1] === `newsletter`) {
    return PageNames.NEWSLETTER;
  } else if (pathName?.startsWith(`/course-details`)) {
    return PageNames.COURSE_DETAILS_PAGE;
  } else return PageNames.STUDENT_PAGE;
};

export { getPageName };
