import { useAppDispatch } from 'app/hooks';

import {
  ModalDataType,
  removeModal,
  updateModal,
} from '@/features/modals/modalsSlice';

import { MODAL_TYPES } from './GlobalModal';

/**
 * Hook for controlling Global Modals.
 */
export default function useModal() {
  const dispatch = useAppDispatch();
  const showModal = ({ modalProps, modalType }: ModalDataType) => {
    dispatch(updateModal({ modalType, modalProps } as ModalDataType));
  };

  const updateModalReducerData = ({ modalProps, modalType }: ModalDataType) => {
    dispatch(updateModal({ modalType, modalProps } as ModalDataType));
  };

  const hideModal = (modalType?: MODAL_TYPES) => {
    dispatch(removeModal(modalType));
  };

  return {
    showModal,
    hideModal,
    updateModalReducerData,
  };
}
