import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import { CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';

import CustomButton from '@/components/CustomMUIComponents/CustomButton';
import { useGetStudentInfoByIdQuery } from '@/services/student';
import { ProfileDataType } from '@/types/profile';

interface ProfileProviderProps {
  children: React.ReactNode;
}

interface ProfileContextInterface {
  profileDetailsData: ProfileDataType;
  refetch: () => void;
  initialProfileDetailsData: ProfileDataType;
}

const initialProfileDetailsData: ProfileDataType = {
  // add leadInfo field here with init values
  leadInformation: {
    leadQuality: `NONE`,
    followUpDate: null,
    counsellorList: [],
    searchByKey: ``,
  },
  academicInfo: [
    {
      university: null,
      course: null,
      grade: null,
      startDate: null,
      endDate: null,
      backLogs: null,
      courseType: ``,
      gradeTotal: null,
      courseLanguage: null,
      city: null,
      country: null,
      documents: [],
    },
  ],
  personalProfile: {
    applicationsCount: null,
    backgroundInformation: [
      {
        questionId: null,
        question: null,
        response: null,
      },
    ],
    id: null,
    editProfile: {
      countryCode: null,
      contactNumber: null,
      contactNumberWithCountryCode: null,
      dob: null,
      email: null,
      firstName: null,
      gender: null,
      lastName: null,
      maritalStatus: null,
      middleName: null,
    },
    personalInformation: {
      dob: null,
      emergencyContactName: null,
      emergencyContactNumber: null,
      emergencyRelationship: null,
      gender: null,
      maritalStatus: null,
      leadStage: ``,
    },
    mailingAddress: {
      addressLine1: null,
      addressLine2: null,
      street: null,
      city: null,
      country: null,
      state: null,
      pinCode: null,
    },
    passportInfo: {
      issueDate: null,
      passportNumber: null,
      expiryDate: null,
      issueCountry: null,
      cityOfBirth: null,
      nationality: null,
    },
    permanentAddress: {
      addressLine1: null,
      addressLine2: null,
      street: null,
      city: null,
      country: null,
      state: null,
      pinCode: null,
    },
  },
  studentExamInfo: [
    {
      totalScore: null,
      readingScore: null,
      writingScore: null,
      examType: ``,
      listeningScore: null,
      speakingScore: null,
      literacy: null,
      conversation: null,
      comprehension: null,
      production: null,
      id: null,
      gmatVerbalScore: null,
      greVerbalScore: null,
      gmatQuantitativeScore: null,
      greQuantScore: null,
      gmatWritingScore: null,
      greWritingTest: null,
      gmatIntegratedReasoning: null,
      gmatExamDate: null,
      greExamDate: null,
      examDate: null,
      documents: [],
    },
  ],
  workExperience: [
    {
      companyName: ``,
      employmentType: null,
      designation: null,
      startDate: null,
      endDate: null,
      isWorkingPresently: null,
      id: null,
      documents: [],
    },
  ],
  info: null,
};
export const ProfileContext = createContext<ProfileContextInterface>({
  profileDetailsData: initialProfileDetailsData,
  refetch: () => null,
  initialProfileDetailsData: initialProfileDetailsData,
});
const ProfileProvider: FunctionComponent<ProfileProviderProps> = (props) => {
  const { children } = props;
  const [profileDetailsData, setProfileDetailsData] = useState<ProfileDataType>(
    initialProfileDetailsData,
  );
  const router = useRouter();
  const { data, isError, isLoading, refetch, isFetching } =
    useGetStudentInfoByIdQuery(
      { id: Number(router?.query?.studentId) },
      {
        skip: router?.query?.studentId ? false : true,
      },
    );
  useEffect(() => {
    if (router?.query?.studentId) {
      refetch();
    }
  }, []);
  useEffect(() => {
    if (data?.data) {
      setProfileDetailsData(data?.data as any);
    }
  }, [data, isFetching]);
  if (isLoading) {
    return (
      <div className="flex items-center h-[100vh] justify-center">
        <CircularProgress />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="flex flex-col items-center h-[100vh] justify-center text-center text-[30px]">
        ...OOPS! Something Went Wrong.Please try again :)
        <div className="my-2">
          <CustomButton
            text={`Refresh the Page`}
            onClick={() => window.location.reload()}
          ></CustomButton>
        </div>
      </div>
    );
  }
  return (
    <ProfileContext.Provider
      value={{
        profileDetailsData,
        initialProfileDetailsData,
        refetch,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
