import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@/app/store';
import { Permissions } from '@/permissions/featuresList';
import { identifyUser } from '@/segment/eventTracker';
import { studentApi } from '@/services/student';
import { UserConfig } from '@/types';
const initialState = {} as { userConfig: UserConfig };
//[Todo]: Move to context or window obj
export const userSlice = createSlice({
  name: `user `,
  initialState,
  reducers: {
    updateConfig: (state, action: PayloadAction<UserConfig>) => {
      state.userConfig = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      studentApi.endpoints.getCspProfile.matchFulfilled,
      (state, { payload }) => {
        identifyUser({
          ...(payload.data as any),
        });
        state.userConfig = {
          userId: payload?.data?.userId,
          cspId: payload?.data?.cspId,
          email: payload?.data?.email,
          firstName: payload?.data?.firstName,
          lastName: payload?.data?.lastName,
          middleName: payload?.data?.middleName,
          partnerName: payload?.data?.partnerName,
          profilePicture: payload?.data?.profilePicture,
          userName: payload?.data?.userName,
          permissions: payload?.data?.permissions,
          // ([Permissions.SUB_AGENT] as any) ?? payload?.data?.permissions, //[TODO]: Remove this
          centreId: payload?.data?.centreId,
          cspSourceCountryId: payload?.data?.cspSourceCountryId,
          applicationReadinessFeaturePermission:
            payload?.data?.applicationReadinessFeaturePermission ?? false,
          logoPreSignedUrl: payload?.data?.logoPreSignedUrl,
          unisetuDashboardFeatureEnabled:
            payload?.data?.unisetuDashboardFeatureEnabled ?? false,
          downloadEnabled: payload?.data?.downloadEnabled,
          flywireFeatureEnabled: payload?.data?.flywireFeatureEnabled,
          noResultAlternateFeature:
            payload?.data?.noResultAlternateFeature ?? false,
          promotionalOfferDetails: payload?.data?.promotionalOfferDetails,
          subAgentStatus: payload?.data?.subAgentStatus,
          domain: payload?.data?.domain,
          topFilterViewFeature: payload?.data?.topFilterViewFeature ?? false,
          isStudentLoginFeatureEnabled:
            payload?.data?.isStudentLoginFeatureEnabled,
          isPromotedInstituteCategoryEnabled:
            payload?.data?.isPromotedInstituteCategoryEnabled,
        };
      },
    );
  },
});

export const { updateConfig } = userSlice.actions;

export const selectUserDetails = (state: RootState) => state.user.userConfig;
export const isSubAgentUser = (state: RootState) =>
  (state.user?.userConfig?.permissions?.[0] as any) === Permissions.SUB_AGENT;
export default userSlice.reducer;
