import { store } from '@/app/store';
import { trackClick, trackPage } from '@/segment/eventTracker';
import {
  PageNames,
  SegmentEventSource,
  WidgetFormat,
  WidgetName,
} from '@/types/segmentEvents';

export enum CFEventsType {
  SELECT_STUDENT,
  MY_BOOKMARKS_CLICK,
  NO_RESULTS_MODAL,
  NO_RESULT_SEARCH_AGAIN,
  FIND_COURSES_SEARCH,
  SLIDE_BAR_CLEAR_ALL,
  SORT_BY_CLICKED,
  VIEW_SHORTLIST_CLICKED,
  CARD_ADD_TO_BOOKMARK,
  CARD_REPORT_COURSE,
  PAGE_CHANGE,
  CF_BACK_BUTTON,
  SEARCH_STUDENT_CLICKED,
  REQUEST_SHORTLIST,
  SEARCH_COURSE_CLICKED,
  COURSE_SELECTED,
  GLOBAL_COUNTRY,
  GLOBAL_INTAKE,
  GLOBAL_DEGREE,
  SHOW_OPEN_ONLY,
  UNIVERSITY_SEARCH,
  UNIVERSITY,
  CITY,
  STATE,
  DEGREE,
  COUNTRY,
  ADDITIONAL_FILTER,
  VIEW_COURSE_DETAIL,
  COURSE_NAME,
  PAGINATION_NEXT,
  PAGINATION_PREV,
  CHECK_ELIGIBILTY,
  ADD_COURSE_MANUALLY,
  SEARCH_COURSE_SELECTED,
  COURSE_LINK_CLICKED,
  EXPLORE_COURSES,
  FIND_COURSES,
  FIND_COURSE_CLICKED,
  CANCEL_FILTER,
  SHOW_PREFERRED_ONLY,
  SELECT_PROGRAM,
  VIEW_SELECTED_PROGRAM,
  CLOSE_VIEW_SELECTED_PROGRAM,
  DELETE_PROGRAM,
  DOWNLOAD_COURSES,
  ADD_TO_SHORTLIST_SELECTED_PROGRAM,
  DURATION,
  TUITION_FEES,
  COURSE_FINDER_RESULTS_PAGE,
  UNIVERSITY_TAB_CLICK,
  UNIVERSITY_NAME_CLICK,
  UNIVERSITY_CARD_VIEW_COURSES,
  UNIVERSITY_DEGREE_TABS,
  UNIVERSITY_FOOTER_WEBSITE,
  UNIVERSITY_RAISE_APPLICATION,
  ELIGIBILTY_BUTTON_CLICK,
  ELIGIBLITY_SECTION,
  ELIGIBILTY_CLEAR_ALL,
  SELECT_PROGRAM_TOUR,
  CD_COPY_COURSE_DETAILS,
  CD_ADD_SHORTLIST_HEADER,
  CD_BROWSE_COURSES,
  CD_RAISE_APPLICATION,
  CD_ADD_SHORTLIST_MODAL,
  CD_VIEW_SHORTLIST_IN_MODAL,
  CD_ADD_SHORTLIST_FOOTER,
  CD_CLICK_COURSE_ON_UNIVERSITY_PAGE,
  USP_FILTER_CHIP,
  USP_FILTER_CHIP_REMOVAL,
  NO_RESULT_PROFILE_SHORTLISTING,
  NO_RESULT_CONTACT_SUPPORT,
  NO_RESULT_GO_TO_UNIVERSITY,
  NO_RESULT_COURSE_CARD_COPY_DETAILS,
  NO_RESULT_COURSE_CARD_DOWNLOAD,
  NO_RESULT_COURSE_CARD_ADD_TO_SHORTLIST,
  NO_RESULT_CAROUSEL,
  NO_RESULT_VIEW_ALL_COURSES,
  NO_RESULT_UNIVERSITY_CARD_VIEW_COURSE,
  NO_RESULT_UNIVERSITY_CARD_UNIVERSITY_NAME,
  NO_RESULT_REASON_ITEM_CLICK,
  SELECTED_COURSES_ADD_TO_SHORTLIST,
  SELECTED_COURSES_DOWNLOAD_EXCEL,
  SELECTED_COURSES_DOWNLOAD_PDF,
  SELECTED_COURSES_VIEW_SHORTLISTED_COURSES,
  SELECTED_COURSES_CLOSE_PANEL,
  SELECTED_COURSES_BACK_PANEL,
  SELECTED_COURSES_REMOVE_SELECTION,
  SELECTED_COURSES_ADD_STUDENT,
  SELECTED_COURSES_SELECT_STUDENT,
  SELECTED_COURSES_EXISTING_STUDENT,
  SELECTED_COURSES_CREATE_NEW_STUDENT,
  CLEAR_TOP_FILTER,
  MORE_TOP_FILTER,
  // Profile Shortlist Request
  CROSSELL_PROFILE_SHORTLIST_REQUEST,
  RAISE_SHORTLIST_REQUEST,
}
export class CfEventUtil {
  static triggerCfEvent = (
    eventType: CFEventsType,
    meta?: { name?: string; restInfo?: any },
  ) => {
    let widgetName: WidgetName | undefined;
    let widgetFormat: WidgetFormat | undefined;
    let contentName;
    let contentFormat;
    let downloadFormat;
    const leadStage =
      store.getState()?.studentProfile?.studentPersonalInformation?.leadStage;
    const studentId =
      store.getState()?.studentProfile?.studentPersonalInformation?.id;

    switch (eventType) {
      case CFEventsType.CHECK_ELIGIBILTY:
        widgetName = WidgetName.RESULT_NAV;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Check Student Eligibility`;
        contentFormat = `Button`;
        break;
      case CFEventsType.ADD_COURSE_MANUALLY:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Add Manually`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECT_STUDENT:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Select Student`;
        contentFormat = `Dropdown`;
        break;
      case CFEventsType.MY_BOOKMARKS_CLICK:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `My Bookmarks`;
        contentFormat = `Button`;
        break;
      case CFEventsType.NO_RESULTS_MODAL:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `No result`;
        contentFormat = `Modal`;
        break;
      case CFEventsType.NO_RESULT_SEARCH_AGAIN:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.MODAL;
        contentName = `Search Again`;
        contentFormat = `Button`;
        break;
      case CFEventsType.FIND_COURSES_SEARCH:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Find Courses`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SLIDE_BAR_CLEAR_ALL:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Clear Filter`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SORT_BY_CLICKED:
        widgetName = WidgetName.RESULT_NAV;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Sort by - ${meta?.name ?? ``}`;
        contentFormat = `Dropdown`;
        break;
      case CFEventsType.VIEW_SHORTLIST_CLICKED:
        widgetName = WidgetName.RESULT_NAV;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `View Shortlist`;
        contentFormat = `Button`;
        break;
      case CFEventsType.CARD_REPORT_COURSE:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Report`;
        contentFormat = `Button`;
        break;
      case CFEventsType.CARD_ADD_TO_BOOKMARK:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Add to Bookmark`;
        contentFormat = `Button`;
        break;
      case CFEventsType.PAGE_CHANGE:
        widgetName = WidgetName.PAGINATION;
        widgetFormat = WidgetFormat.PAGINATION;
        contentName = `Page Number`;
        contentFormat = `Button`;
        break;
      case CFEventsType.CF_BACK_BUTTON:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Back`;
        contentFormat = `Button`;
        break;
      case CFEventsType.REQUEST_SHORTLIST:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Request Shortlist`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SEARCH_COURSE_CLICKED:
      case CFEventsType.SEARCH_COURSE_SELECTED:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Search Course`;
        contentFormat = `Button`;

        break;
      case CFEventsType.GLOBAL_COUNTRY:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = meta?.name;
        contentFormat = `Country Filter`;
        break;
      case CFEventsType.GLOBAL_DEGREE:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = meta?.name;
        contentFormat = `Degree Filter`;
        break;
      case CFEventsType.GLOBAL_INTAKE:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = meta?.name;
        contentFormat = `Intake Filter`;
        break;
      case CFEventsType.SHOW_OPEN_ONLY:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Open Only`;
        contentFormat = `Button`;
        break;
      case CFEventsType.UNIVERSITY_SEARCH:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `University Search`;
        contentFormat = `Search`;
        break;
      case CFEventsType.UNIVERSITY:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `University Selected`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.CITY:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `City Selected`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.STATE:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `State Selected`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.COUNTRY:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Country Selected`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.DEGREE:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Degree Selected`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.ADDITIONAL_FILTER:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Additional Filter - ${meta?.name}`;
        contentFormat = `MultiSelect`;
        break;
      case CFEventsType.VIEW_COURSE_DETAIL:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Course Details`;
        contentFormat = `Button`;
        break;
      case CFEventsType.PAGINATION_NEXT:
      case CFEventsType.PAGINATION_PREV:
        widgetName = WidgetName.PAGINATION;
        widgetFormat = WidgetFormat.PAGINATION;
        contentName = meta?.name;
        contentFormat = `Button`;
        break;
      case CFEventsType.COURSE_LINK_CLICKED:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Course Link`;
        contentFormat = `Link`;
        break;
      case CFEventsType.EXPLORE_COURSES:
        widgetName = WidgetName.TOP_CTA;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Explore Courses`;
        contentFormat = `Button`;
        break;
      case CFEventsType.FIND_COURSES:
      case CFEventsType.FIND_COURSE_CLICKED:
        widgetName = WidgetName.SEARCH_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Find Course`;
        contentFormat = `Button`;

        break;
      case CFEventsType.CANCEL_FILTER:
        widgetName = WidgetName.FILTER_SECTION;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Cancel Filter`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SHOW_PREFERRED_ONLY:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Preffered Only`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECT_PROGRAM:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `${meta?.name} Program`;
        contentFormat = `Button`;
        break;
      case CFEventsType.VIEW_SELECTED_PROGRAM:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `View Select Program`;
        contentFormat = `Button`;
        break;
      case CFEventsType.CLOSE_VIEW_SELECTED_PROGRAM:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Close `;
        contentFormat = `Button`;
        break;
      case CFEventsType.DELETE_PROGRAM:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Delete`;
        contentFormat = `Button`;
        break;
      case CFEventsType.DOWNLOAD_COURSES:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Download`;
        contentFormat = `Button`;
        break;
      case CFEventsType.ADD_TO_SHORTLIST_SELECTED_PROGRAM:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Add Shortlist`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SEARCH_STUDENT_CLICKED:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Search Student`;
        contentFormat = `Search`;
        break;
      case CFEventsType.TUITION_FEES:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Tuition Fees - ${meta?.name}`;
        contentFormat = `Search`;
        break;
      case CFEventsType.DURATION:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `Duration - ${meta?.name}`;
        contentFormat = `Search`;
        break;
      case CFEventsType.UNIVERSITY_TAB_CLICK:
        widgetName = WidgetName.UNIVERSITY_COURSE_TAB;
        widgetFormat = WidgetFormat.TAB;
        contentName = `${meta?.name}`;
        contentFormat = `Tab`;
        break;
      case CFEventsType.UNIVERSITY_NAME_CLICK:
        widgetName = WidgetName.UNIVERSITY_NAME;
        widgetFormat = WidgetFormat.UNIVERSITY_CARD;
        contentName = `University Name`;
        contentFormat = `University Card`;
        break;
      case CFEventsType.UNIVERSITY_CARD_VIEW_COURSES:
        widgetName = WidgetName.COURSE_COUNT;
        widgetFormat = WidgetFormat.UNIVERSITY_CARD;
        contentName = `View Courses`;
        contentFormat = `University Card`;
        break;
      case CFEventsType.UNIVERSITY_DEGREE_TABS:
        widgetName = WidgetName.DEGREE_TAB_UNIVERISTY_PAGE;
        widgetFormat = WidgetFormat.BUTTON;
        contentName = WidgetName.DEGREE_TAB_UNIVERISTY_PAGE;
        contentFormat = `Button`;
        break;
      case CFEventsType.UNIVERSITY_FOOTER_WEBSITE:
        widgetName = WidgetName.UNIVERSITY_WEBSITE;
        widgetFormat = WidgetFormat.BUTTON;
        contentName = WidgetName.UNIVERSITY_WEBSITE;
        contentFormat = `Button`;
        break;
      case CFEventsType.UNIVERSITY_RAISE_APPLICATION:
        widgetName = WidgetName.UNIVERSITY_RAISE_APPLICATION;
        widgetFormat = WidgetFormat.BUTTON;
        contentName = WidgetName.UNIVERSITY_RAISE_APPLICATION;
        contentFormat = `Button`;
        break;
      case CFEventsType.ELIGIBILTY_BUTTON_CLICK:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.BUTTON;
        contentName = `Check eligible courses`;
        contentFormat = `Button`;
        break;
      case CFEventsType.ELIGIBILTY_CLEAR_ALL:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.BUTTON;
        contentName = `Clear all`;
        contentFormat = `Button`;
        break;
      case CFEventsType.ELIGIBLITY_SECTION:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = meta?.name;
        contentFormat = meta?.restInfo?.format;
        break;
      case CFEventsType.SELECT_PROGRAM_TOUR:
        widgetName = WidgetName.SELECT_PROGRAM_TOUR;
        widgetFormat = WidgetFormat.TOUR;
        contentName = meta?.name;
        break;
      case CFEventsType.CD_COPY_COURSE_DETAILS:
        widgetName = WidgetName.COURSE_DETAILS_HEADER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Button`;
        contentName = `Copy Button`;
        break;
      case CFEventsType.CD_ADD_SHORTLIST_HEADER:
        widgetName = WidgetName.COURSE_DETAILS_HEADER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Button`;
        contentName = `Add Shortlist`;
        break;
      case CFEventsType.CD_ADD_SHORTLIST_FOOTER:
        widgetName = WidgetName.COURSE_DETAILS_FOOTER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Button`;
        contentName = `Add Shortlist`;
        break;
      case CFEventsType.CD_BROWSE_COURSES:
        widgetName = WidgetName.COURSE_DETAILS_FOOTER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Button`;
        contentName = `University Courses`;
        break;
      case CFEventsType.CD_RAISE_APPLICATION:
        widgetName = WidgetName.COURSE_DETAILS_FOOTER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Button`;
        contentName = `Raise Application`;
        break;
      case CFEventsType.CD_ADD_SHORTLIST_MODAL:
        widgetName =
          meta?.restInfo?.source === SegmentEventSource.CF
            ? WidgetName.NO_RESULT_SECTION
            : WidgetName.COURSE_DETAILS_SHORTLIST_MODAL;
        widgetFormat = WidgetFormat.CARD;
        contentFormat = `Button`;
        contentName = `Add Shortlist`;
        break;
      case CFEventsType.CD_VIEW_SHORTLIST_IN_MODAL:
        widgetName =
          meta?.restInfo?.source === SegmentEventSource.CF
            ? WidgetName.NO_RESULT_SECTION
            : WidgetName.COURSE_DETAILS_SHORTLIST_MODAL;
        widgetFormat = WidgetFormat.CARD;
        contentFormat = `Button`;
        contentName = `View Shortlisted Course`;
        break;
      case CFEventsType.CD_CLICK_COURSE_ON_UNIVERSITY_PAGE:
        widgetName = WidgetName.COURSE_DETAILS_HEADER;
        widgetFormat = WidgetFormat.BANNER;
        contentFormat = `Link`;
        contentName = `Course on University Page`;
        break;

      case CFEventsType.USP_FILTER_CHIP:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `USP Chip`;
        contentFormat = `Chip`;
        break;
      case CFEventsType.USP_FILTER_CHIP_REMOVAL:
        widgetName = WidgetName.FILTER_SIDEBAR;
        widgetFormat = WidgetFormat.SIDEBAR;
        contentName = `USP Removal`;
        contentFormat = `Chip`;
        break;
      case CFEventsType.NO_RESULT_PROFILE_SHORTLISTING:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Profile Shortlist Request`;
        contentFormat = `Card`;
        break;
      case CFEventsType.NO_RESULT_CONTACT_SUPPORT:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Contact Support Team`;
        contentFormat = `Card`;
        break;
      case CFEventsType.NO_RESULT_GO_TO_UNIVERSITY:
      case CFEventsType.NO_RESULT_VIEW_ALL_COURSES:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Go to University page`;
        contentFormat = `Card`;
        break;
      case CFEventsType.NO_RESULT_COURSE_CARD_COPY_DETAILS:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Copy Button`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.NO_RESULT_COURSE_CARD_DOWNLOAD:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Download`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.NO_RESULT_COURSE_CARD_ADD_TO_SHORTLIST:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Add Shortlist`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.NO_RESULT_CAROUSEL:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `carousal slider`;
        contentFormat = `slider`;
        break;
      case CFEventsType.NO_RESULT_UNIVERSITY_CARD_VIEW_COURSE:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `View Courses`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.NO_RESULT_UNIVERSITY_CARD_UNIVERSITY_NAME:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `University Name`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.NO_RESULT_REASON_ITEM_CLICK:
        widgetName = WidgetName.NO_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `next steps item`;
        contentFormat = `Result Card`;
        break;
      case CFEventsType.SELECTED_COURSES_ADD_TO_SHORTLIST:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Add Shortlist`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECTED_COURSES_DOWNLOAD_EXCEL:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Download`;
        contentFormat = `Button`;
        downloadFormat = `CSV`;
        break;
      case CFEventsType.SELECTED_COURSES_DOWNLOAD_PDF:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Download`;
        contentFormat = `Button`;
        downloadFormat = `PDF`;
        break;
      case CFEventsType.SELECTED_COURSES_VIEW_SHORTLISTED_COURSES:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `View Shortlisted Course`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECTED_COURSES_CLOSE_PANEL:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Close`;
        contentFormat = `Icon`;
        break;
      case CFEventsType.SELECTED_COURSES_BACK_PANEL:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Back`;
        contentFormat = `Icon`;
        break;
      case CFEventsType.SELECTED_COURSES_REMOVE_SELECTION:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Delete`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECTED_COURSES_ADD_STUDENT:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Add Student`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECTED_COURSES_SELECT_STUDENT:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Select Student`;
        contentFormat = `Button`;
        break;

      case CFEventsType.CLEAR_TOP_FILTER:
        widgetName = WidgetName.TOP_FILTER;
        widgetFormat = WidgetFormat.BUTTON;
        contentFormat = `Button`;
        contentName = `Clear Filter`;
        break;

      case CFEventsType.MORE_TOP_FILTER:
        widgetName = WidgetName.TOP_FILTER;
        widgetFormat = WidgetFormat.BUTTON;
        contentFormat = `Button`;
        contentName = `${meta?.name} Filter`;
        break;

      case CFEventsType.SELECTED_COURSES_EXISTING_STUDENT:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Select Existing Student`;
        contentFormat = `Button`;
        break;
      case CFEventsType.SELECTED_COURSES_CREATE_NEW_STUDENT:
        widgetName = WidgetName.RESULT_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Create new Student`;
        contentFormat = `Button`;
        break;
      case CFEventsType.CROSSELL_PROFILE_SHORTLIST_REQUEST:
        widgetName = WidgetName.COURSE_RESULT_SECTION;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Profile Shortlist Request`;
        contentFormat = `Card`;
        break;

      case CFEventsType.RAISE_SHORTLIST_REQUEST:
        widgetName = WidgetName.SHORLIST_REQUEST;
        widgetFormat = WidgetFormat.MODAL;
        contentName = `Submit Request`;
        contentFormat = `Button`;
        break;
      default:
        break;
    }

    if (widgetFormat && widgetName) {
      trackClick({
        widgetFormat,
        widgetName,
        contentFormat,
        contentName,
        leadStage,
        studentId,
        ...(eventType == CFEventsType.ELIGIBLITY_SECTION ? {} : meta?.restInfo),
      });
    }
  };
}

export class CfPageViewedEventUtil {
  static triggerCfEvent = (eventType: CFEventsType, restInfo?: any) => {
    let pageName;
    if (eventType === CFEventsType?.COURSE_FINDER_RESULTS_PAGE) {
      pageName = PageNames.COURSE_FINDER_RESULTS_PAGE;
    }
    // switch (eventType) {
    //   case CFEventsType.COURSE_FINDER_RESULTS_PAGE:
    //     pageName = PageNames.COURSE_FINDER_RESULTS_PAGE;
    //     break;
    //   default:
    //     break;
    // }

    trackPage({
      pageName,
      ...restInfo,
    });
  };
}
