import dynamic from 'next/dynamic';

export const StudentProfileSection = dynamic(
  () => import(`./StudentProfileSection`),
  {
    ssr: false,
  },
);

export const ApplicationSectionLayout = dynamic(
  () => import(`./ApplicationSection/ApplicationSectionLayout`),
  {
    ssr: false,
  },
);

export const VisaSection = dynamic(() => import(`./VisaSection`), {
  ssr: false,
});

export const ShortlistSectionLayout = dynamic(
  () => import(`./CF/Shortlist/ShortlistSectionLayout`),
  {
    ssr: false,
  },
);
export const DocumentSectionLayout = dynamic(
  () => import(`./DocumentSection/DocumentLayout`),
  {
    ssr: false,
  },
);

export const CommentLayout = dynamic(() => import(`./Comments/CommentLayout`), {
  ssr: false,
});
export const DocumentAccordionLayout = dynamic(
  () => import(`./DocumentAccordian`),
  {
    ssr: false,
  },
);
export const MoreFilter = dynamic(() => import(`./ApplicationMoreFilter`), {
  ssr: false,
});

export const StudentMoreFilter = dynamic(() => import(`./StudentMoreFilter`), {
  ssr: false,
});

export const UploadedDocumentChipList = dynamic(
  () => import(`./DocumentSection/UploadedDocumentChipList`),
  { ssr: false },
);

export const ConfirmDialog = dynamic(() => import(`./ConfirmDialog`));
