import { isFalsy } from '@leapfinance/frontend-commons/utils/common';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState, store } from '@/app/store';
import { applicationApi, studentApi } from '@/services/student';
import { StudentProfileConfig } from '@/types';
import { definitions } from '@/types/schema';

const initialState = {} as {
  studentProfileConfig: StudentProfileConfig;
  studentPersonalInformation: definitions[`PersonalInfoDto`] & {
    id: number | undefined;
  };
  leadInformation: definitions[`LeadInformationDto`];
  isStudentLoginEnabled?: boolean;
  passportInfo: definitions['PassportDto'];
  isStudentProfileComplete?: boolean;
};
//[Todo]: Move to context or window obj
export const studentProfileSlice = createSlice({
  name: `studentProfile`,
  initialState,
  reducers: {
    updateStudentProfileConfig: (
      state,
      action: PayloadAction<StudentProfileConfig>,
    ) => {
      state.studentProfileConfig = action.payload;
    },
    updateStudentProfileData: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      state.studentProfileConfig =
        (data?.personalProfile?.editProfile as StudentProfileConfig) || {};
      state.studentPersonalInformation = {
        ...(data?.personalProfile
          ?.personalInformation as definitions[`PersonalInfoDto`]),
        id: data?.personalProfile?.id,
      };
      state.leadInformation =
        (data?.leadInformation as definitions[`StudentDetailsViewDto`][`leadInformation`]) ||
        {};
      state.passportInfo = data?.personalProfile?.passportInfo;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        studentApi.endpoints.getStudentInfoById.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.studentProfileConfig =
            (data?.personalProfile?.editProfile as StudentProfileConfig) || {};
          state.studentPersonalInformation = {
            ...(data?.personalProfile
              ?.personalInformation as definitions[`PersonalInfoDto`]),
            id: data?.personalProfile?.id,
          };
          state.leadInformation =
            (data?.leadInformation as definitions[`StudentDetailsViewDto`][`leadInformation`]) ||
            {};
          state.isStudentLoginEnabled = data?.isStudentLoginCreated;
        },
      )
      .addMatcher(
        applicationApi.endpoints.fetchStudentProfileStatus.matchPending,
        (state, { payload }) => {
          state.isStudentProfileComplete = false;
        },
      )
      .addMatcher(
        applicationApi.endpoints.fetchStudentProfileStatus.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;

          state.isStudentProfileComplete = data !== false;
        },
      );
  },
});

export const { updateStudentProfileConfig, updateStudentProfileData } =
  studentProfileSlice.actions;

export const selectStudentProfileDetails = (state: RootState) =>
  state.studentProfile.studentProfileConfig;

export const selectStudentPersonalInformation = (state: RootState) =>
  state.studentProfile.studentPersonalInformation;

export const selectStudentLeadDetails = (state: RootState) =>
  state.studentProfile.leadInformation;

export const selectIsStudentLoginEnabled = (state: RootState) =>
  state.studentProfile.isStudentLoginEnabled;

export const selectStudentPassportInfo = (state: RootState) =>
  state.studentProfile.passportInfo;

export const selectIsStudentProfileComplete = (state: RootState) =>
  state.studentProfile.isStudentProfileComplete;

export const getStudentName = (state: RootState) => {
  const studentConfig = state.studentProfile.studentProfileConfig;
  if (!studentConfig) return null;
  const { firstName, lastName, contactNumberWithCountryCode, email } =
    studentConfig;
  return (
    firstName +
    ` ` +
    (lastName || ``) +
    ` - ` +
    (contactNumberWithCountryCode || ``) +
    ` - ` +
    (email || ``)
  );
};

export const encodedStudentObject = (studentId?: number): string | null => {
  if (isFalsy(studentId) || Number.isNaN(studentId)) return null;
  const studentName = getStudentName(store.getState());
  if (isFalsy(studentName)) return null;

  let encodedStudent: string | null = null;
  try {
    encodedStudent = encodeURIComponent(
      JSON.stringify({ studentId, studentName }),
    );
  } catch (error) {
    console.log(`Error in encoding student name`, error);
  } finally {
    return encodedStudent;
  }
};

export default studentProfileSlice.reducer;
