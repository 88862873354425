import React, { useEffect, useState } from 'react';
import { type InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

function CustomSortReactSelect(props: StateManagerProps) {
  const [documentOptions, setDocumentOptions] = useState(props.options);

  useEffect(() => {
    setDocumentOptions(props.options);
  }, [props.options]);

  const customSort = (a: any, b: any, rawInput: string) => {
    const inputValue = rawInput.trim().toLowerCase();
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA.startsWith(inputValue) && labelB.startsWith(inputValue)) {
      return labelA.localeCompare(labelB);
    }

    if (labelA.startsWith(inputValue)) {
      return -1;
    }

    if (labelB.startsWith(inputValue)) {
      return 1;
    }

    return labelA.localeCompare(labelB);
  };

  const onInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === `input-change`) {
      const filteredOptions = (documentOptions as any).toSorted(
        (a: any, b: any) => customSort(a, b, value),
      );
      setDocumentOptions(filteredOptions);
    } else if (meta.action === `menu-close`) {
      setDocumentOptions(documentOptions);
    }
  };

  return (
    <CreatableSelect
      {...props}
      options={documentOptions as any}
      onInputChange={onInputChange}
    />
  );
}

export default CustomSortReactSelect;
