import * as next from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

const MAX_AGE = 60 * 60 * 24 * 30; // 30 days

export type CookieReqContext =
  | Pick<next.NextPageContext, 'req'>
  | { req: next.NextApiRequest }
  | null
  | undefined;

export type CookieResContext =
  | Pick<next.NextPageContext, 'res'>
  | { res: next.NextApiResponse }
  | null
  | undefined;

export function saveCookie(
  ctx: CookieResContext,
  key: string,
  value: string,
  ttl?: number,
  isDevEnv?: boolean,
) {
  setCookie(ctx, key, value, {
    maxAge: ttl || MAX_AGE,
    expires: ttl
      ? new Date(Date.now() + ttl * 1000)
      : new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === `production`,
    path: `/`,
    sameSite: `lax`,
    domain: isDevEnv ? undefined : `.unisetu.com`,
  });
}

export function deleteCookie(
  ctx: CookieResContext,
  key: string,
  isDevEnv?: boolean,
) {
  destroyCookie(ctx, key, {
    maxAge: -1,
    path: `/`,
    domain: isDevEnv ? undefined : `.unisetu.com`,
  });
}

export function getCookie(ctx: CookieReqContext, key: string): string {
  const cookies = parseCookies(ctx);
  return cookies[key];
}
